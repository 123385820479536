
import {Component, Mixins, Ref, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import {VForm} from "@/types";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import HacizTalepHazirlaForm from "@/components/forms/HacizTalepHazirlaForm.vue";
import {ITask, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import UyapHacizTalepListForm from "@/components/forms/form-wizard/eicrapro/UyapHacizTalepListForm.vue";
import AvukatTalepPicker from "@/components/pickers/uyap/AvukatTalepPicker.vue";
import MahrumiyetInfo from "@/components/infos/eicrapro/MahrumiyetInfo.vue";
import BankaHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/BankaHacziTalepEvrakForm.vue";
import MaasHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/MaasHacziTalepEvrakForm.vue";
import SgkMossipHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/SgkMossipHacziTalepEvrakForm.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import TasinirHacizTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/TasinirHacizTalepEvrakForm.vue";
import TasinirHacizTalimatiTalepEvrakForm
  from "@/components/forms/uyap-evrak/haciz-evrak/TasinirHacizTalimatiTalepEvrakForm.vue";
import IhbarnameTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/IhbarnameTalepEvrakForm.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import {
  TopluHacizHazirlaTaskHandler
} from "@/plugins/uyap-plugin/store/modules/TaskHandler/TopluHacizHazirlaTaskHandler";
import {ProgramPaths} from "@/enum/ProgramPaths";
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import NewFormDialog from "@/components/dialogs/NewFormDialog.vue";
import TopluHacizTalepDuzenleForm from "@/components/eicrapro/TopluHacizTalepDuzenleForm.vue";

@Component({
  computed: {
    MDB() {
      return MDB
    }
  },
  components: {
    TopluHacizTalepDuzenleForm,
    NewFormDialog,
    NewFormWrapper,
    FormWrapper,
    IhbarnameTalepEvrakForm,
    TasinirHacizTalimatiTalepEvrakForm,
    TasinirHacizTalepEvrakForm,
    SgkMossipHacziTalepEvrakForm,
    MaasHacziTalepEvrakForm,
    BankaHacziTalepEvrakForm,
    MahrumiyetInfo,
    AvukatTalepPicker,
    UyapHacizTalepListForm, HacizTalepHazirlaForm, FormDialog, PersonelPicker, GenelIslemFormWizard
  }
})

export default class TopluHacizHazirlaFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genelIslemForm') genelIslemForm!: GenelIslemFormWizard;

  // TODO: genel işleme task için ayrı class ekle
  localValue: GenelIslemEntity = new GenelIslemEntity();
  taskDurum = TaskDurum;
  topluHacizDurum = TopluHacizDurum;
  durum: TopluHacizDurum = this.topluHacizDurum.bosta;
  hacizEvrakTuru = UyapHacizEvrakTuru;
  valid = false;
  onFly = false;
  hideNext = false;
  talepler: Array<AvukatTalepListCevapRow> = [];
  message = "";

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Haciz Evraklarını Hazırla",
    "Haciz Evraklarını Gönder",
    "İşlemler Sıraya Alındı"
  ];
  secilenHeaders = [
    {text: "Birim Adı", value: "birimAdi"},
    {text: "Dosya Esas No", value: "dosyaNo"},
    {text: "Açılış Tarihi", value: "dosyaAcilisTarihi"},
    {text: "Dosya Durumu", value: "dosyaDurum"}
    /* {text: "Durum", value: "status", align: 'center'}*/
  ];

  tarihStr(dosyaAcilisTarihi: any) {
    let day = dosyaAcilisTarihi.date.day < 10 ? ("0" + dosyaAcilisTarihi.date.day) : dosyaAcilisTarihi.date.day;
    let month = dosyaAcilisTarihi.date.month < 10 ? ("0" + dosyaAcilisTarihi.date.month) : dosyaAcilisTarihi.date.month;
    let hour = dosyaAcilisTarihi.time.hour < 10 ? ("0" + dosyaAcilisTarihi.time.hour) : dosyaAcilisTarihi.time.hour;
    let minute = dosyaAcilisTarihi.time.minute < 10 ? ("0" + dosyaAcilisTarihi.time.minute) : dosyaAcilisTarihi.time.minute;
    let second = dosyaAcilisTarihi.time.second < 10 ? ("0" + dosyaAcilisTarihi.time.second) : dosyaAcilisTarihi.time.second;
    let tarih = day + "." + month + "." + dosyaAcilisTarihi.date.year + " " +
        hour + ":" + minute + ":" + second;
    return tarih;
  }

  talepDuzenleHeaders = [
    {text: "Dosya", value: "dosya", width: "400"},
    {text: "Borçlu", value: "borclu", width: "350"},
    {text: "Durum", value: "durum", sortable: false},
    {text: "İşlemler", value: "actions", width: "50", sortable: false}
  ];

  varsayilanTalepler = {
    bankaHaczi: {
      muafBankaList: [],
      hesapList: [],
      evrakAdi: "",
      evrakAdiSTR: "",
      haricAdi: "",
      haricAdiSTR: "",
      tebligTarihi: "",
    },
    maasHaczi: {
      kurumId: 0,
      kurumAdi: "",
      calismaDurumu: null,
      calismaDurumuText: "",
      maasininBirBoluDordu: false,
      alacaklarininTamami: false,
      emekliIkramiyeHaczi: "",
      nafaka: false,
      nafakaTutari: "",
      aciklama: ""
    },
    tasinirHaczi: {
      aciklama: ""
    },
    tasinirHacizTalimati: {
      birimId: "",
      birimAdi: "",
      aciklama: ""
    },
    ihbarname89_1: {
      tebligatTuru: "",
      tebligatTuruAciklama: "",
      adresTuru: "",
      adresTuruAciklama: "",
      adres: ""
    },
    ihbarname89_2: {
      tebligTarihi: "",
      tebligatTuru: "",
      tebligatTuruAciklama: "",
      adresTuru: "",
      adresTuruAciklama: "",
      adres: ""
    },
    ihbarname89_3: {
      tebligTarihi: "",
      tebligatTuru: "",
      tebligatTuruAciklama: "",
      adresTuru: "",
      adresTuruAciklama: "",
      adres: ""
    }
  }

  async mounted() {
    let hesapList = (await this.$uyap.BankaHacziParams().run({})).hesaplar.sort((a, b) => (a.aciklama.localeCompare(b.aciklama)));
    this.varsayilanTalepler.bankaHaczi={
      muafBankaList: [],
      hesapList: hesapList,
      evrakAdi: "MZK",
      evrakAdiSTR: "Haciz Müzekkeresi",
      haricAdi: "MAAS",
      haricAdiSTR: "Maaş Hesabı Hariç",
      tebligTarihi: "",
    }
  }

  async setVarsayilanTalep(hacizEvrakTuru: UyapHacizEvrakTuru) {
    for (let takip of this.localValue.secilen_takipler) {
      for (let borclu of takip.task.response.borclular) {
        switch (hacizEvrakTuru) {
          case UyapHacizEvrakTuru.banka_haczi:
            await this.setVarsayilanBankaHaczi(borclu);
            break;
          case UyapHacizEvrakTuru.maas_haczi:
            await this.setVarsayilanMaasHaczi(borclu);
            break;
          case UyapHacizEvrakTuru.tasinir_haciz:
            this.setVarsayilanTasinirHaczi(borclu);
            break;
          case UyapHacizEvrakTuru.tasinir_haciz_talimati:
            this.setVarsayilanTasinirHacizTalimati(borclu);
            break;
          case UyapHacizEvrakTuru.ihbarname89_1:
            await this.setVarsayilanIhbarname89_1(borclu);
            break;
          case UyapHacizEvrakTuru.ihbarname89_2:
            await this.setVarsayilanIhbarname89_2(borclu);
            break;
          case UyapHacizEvrakTuru.ihbarname89_3:
            await this.setVarsayilanIhbarname89_3(borclu);
            break;
        }
      }
    }
  }

  async setVarsayilanBankaHaczi(borclu: any) {
    let varsayilanBankaHaczi = this.varsayilanTalepler.bankaHaczi;
    let bankalar = (await this.$uyap.BankaHacziParams().run({})).bankalar.sort((a, b) => (a.ilgili.localeCompare(b.ilgili)));
    bankalar.forEach((banka) => {
      banka.kisaAd = banka.ilgili
          .replace("TÜRK ANONİM ŞİRKETİ", "T.A.Ş.")
          .replace("ANONİM ŞİRKETİ", "A.Ş.")
          .replace("TÜRK ANONİM ORTAKLIĞI", "T.A.O.")
          .replace("TÜRKİYE CUMHURİYETİ", "T.C.")
          .replace(" GENEL MÜDÜRLÜĞÜ", "")
          .toLocaleLowerCase('tr')
    });
    let mevcutBorcluBankalar = borclu.bankaBilgileri.sorguSonucDVO.map((banka: any) => banka.bankaAdi.toLocaleLowerCase('tr'));
    let secilecekBorcluBankalar = bankalar.filter(banka => mevcutBorcluBankalar.includes(banka.kisaAd) && !varsayilanBankaHaczi.muafBankaList.includes(banka.ilgiliKisiIlkKisiKurumId)).map(banka=>banka.ilgiliKisiIlkKisiKurumId);
    borclu.haciz.bankaHesapHacziParams = {
      bankaList: secilecekBorcluBankalar,
      hesapList: varsayilanBankaHaczi.hesapList,
      evrakAdi: varsayilanBankaHaczi.evrakAdi,
      evrakAdiSTR: varsayilanBankaHaczi.evrakAdiSTR,
      haricAdi: varsayilanBankaHaczi.haricAdi,
      haricAdiSTR: varsayilanBankaHaczi.haricAdiSTR,
      tebligTarihi: varsayilanBankaHaczi.tebligTarihi
    }
  }

  async setVarsayilanMaasHaczi(borclu: any){
    let varsayilanMaasHaczi = this.varsayilanTalepler.maasHaczi;
    if (varsayilanMaasHaczi.calismaDurumu == 0 || varsayilanMaasHaczi.calismaDurumu == 1) {

    }
  }

  setVarsayilanTasinirHaczi(borclu: any){
    borclu.haciz.tasinirHacizParams = this.varsayilanTalepler.tasinirHaczi.aciklama;
  }

  setVarsayilanTasinirHacizTalimati(borclu: any){
    let varsayilanTasinirHacizTalimati = this.varsayilanTalepler.tasinirHacizTalimati;
    borclu.haciz.tasinirHacizTalimatiParams = {
      aciklama: varsayilanTasinirHacizTalimati.aciklama,
      birimAdi: varsayilanTasinirHacizTalimati.birimAdi,
      birimId: varsayilanTasinirHacizTalimati.birimId
    }
  }

  async setVarsayilanIhbarname89_1(borclu: any){
    let varsayilanIhbarname89_1 = this.varsayilanTalepler.ihbarname89_1;

  }

  async setVarsayilanIhbarname89_2(borclu: any){
    let varsayilanIhbarname89_2 = this.varsayilanTalepler.ihbarname89_2;

  }

  async setVarsayilanIhbarname89_3(borclu: any){
    let varsayilanIhbarname89_3 = this.varsayilanTalepler.ihbarname89_3;

  }

  get showTalepAyar(): boolean {
    let talepAyarItems = [
      this.hacizEvrakTuru.banka_haczi,
      this.hacizEvrakTuru.maas_haczi,
      this.hacizEvrakTuru.tasinir_haciz,
      this.hacizEvrakTuru.tasinir_haciz_talimati,
      this.hacizEvrakTuru.ihbarname89_1,
      this.hacizEvrakTuru.ihbarname89_2,
      this.hacizEvrakTuru.ihbarname89_3
    ];
    let show=false;
    for (const talep of this.talepler) {
      if (talepAyarItems.includes(talep.talepKodu)) {
        show = true;
        break;
      }
    }
    return show;
  }

  @Watch('localValue.secilen_takipler')
  async onSecilenTakiplerChange() {
    let secilenTakip: any;
    for (secilenTakip of this.localValue.secilen_takipler) {
      if (!secilenTakip.task) {
        secilenTakip.task = new Task();
        secilenTakip.task.dosya_esas_no = secilenTakip.dosyaNo;
        secilenTakip.task.birim_adi = secilenTakip.birimAdi;
        secilenTakip.task.birimId = secilenTakip.birimAdi;
        secilenTakip.task.dosyaAcilisTarihi = new Date(this.$uyapHelper.formatDate(secilenTakip.dosyaAcilisTarihi)).toString();
        secilenTakip.task.task_type_id = TaskTuru.toplu_haciz_hazirla;
        secilenTakip.task.dosya_id = secilenTakip.dosyaId;
        this.input();
      }
    }
  }

  onStepChange(stepNo: number) {
    if (stepNo == 3)
      this.hideNext = true;
    else
      this.hideNext = false;
  }

  onTalepChange() {
    this.localValue.secilen_takipler.forEach(takip => {
      if (takip.task && (takip.task.status == this.taskDurum.BITTI_BASARILI || takip.task.status == this.taskDurum.BITTI_HATALI))
        takip.task.status = this.taskDurum.SIRADA;
    })
    this.input();
  }

  async createHacizHazirlaTask() {
    if (this.form.validate()) {
      this.onFly = true;
      this.durum = this.topluHacizDurum.hazirlaniyor;
      this.message = "Talepler hazırlanıyor...";
      for (let [index, takip] of Object.entries(this.localValue.secilen_takipler)) {
        // @ts-ignore
        let task: ITask = takip.task;
        if (!task.status) {
          task.status = TaskDurum.SIRADA;
        }
        try {
          this.message = (Number(index) + 1) + ". dosyanın talepleri hazırlanıyor.";
          if (task.status == TaskDurum.SIRADA) {
            task.data = {hacizParams: this.talepler};
            let handler = new TopluHacizHazirlaTaskHandler();
            let taskResult = await handler.handle(task);
            console.log('RESULT--------->',taskResult)
            task = taskResult;
            task.status = this.taskDurum.BITTI_BASARILI;
          }
          this.message = Number(index + 1) + ". dosyanın varsayılan talepleri uygulanıyor."
          for (let borclu of task.response.borclular) {
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.banka_haczi))
              await this.setVarsayilanBankaHaczi(borclu);
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.maas_haczi))
              await this.setVarsayilanMaasHaczi(borclu);
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.tasinir_haciz))
              this.setVarsayilanTasinirHaczi(borclu);
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.tasinir_haciz_talimati))
              this.setVarsayilanTasinirHacizTalimati(borclu);
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.ihbarname89_1))
              await this.setVarsayilanIhbarname89_1(borclu);
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.ihbarname89_2))
              await this.setVarsayilanIhbarname89_2(borclu);
            if (this.talepler.some(talep => talep.talepKodu == this.hacizEvrakTuru.ihbarname89_3))
              await this.setVarsayilanIhbarname89_3(borclu);
          }
          this.message = Number(index + 1) + ". dosyanın talepleri hazır."
          console.log('TAKİPP',takip)
        } catch (err) {
          console.log('burda mı hata????',err)
          task.status = this.taskDurum.BITTI_HATALI;
          this.message = Number(index + 1) + ". dosyanın talepleri hazırlanırken hata oluştu. (" + err + ")"
          task.description = err;
        } finally {
          this.input();
        }
      }
      this.durum = this.topluHacizDurum.hazir;
      this.genelIslemForm.nextStep();
      this.onFly = false;
    }
  }

  showTalep(talepKod: UyapHacizEvrakTuru): boolean {
    return !!this.talepler.find((talep: AvukatTalepListCevapRow) => (talep.talepKodu == talepKod));
  }

  addAllTasinirDosya(data: any, takip: any) {
    takip.task.response.borclular.forEach((borclu: any) => {
      borclu.haciz.tasinirHacizParams = (data);
    });
    this.input();
  }

  addAllTasinirDosyalar(data: any) {
    this.localValue.secilen_takipler.forEach(takip => {
      this.addAllTasinirDosya(data, takip);
    });
  }

  addAllBankaDosya(data: any, takip: any) {
    takip.task.response.borclular.forEach((borclu: any) => {
      if (!borclu.kurumAdi && data.haricAdi == 'BELEDIYE')
        delete data.haricAdi
      else if (borclu.kurumAdi && data.haricAdi == 'MAAS')
        delete data.haricAdi;
      borclu.haciz.bankaHesapHacziParams = data;
    });
    this.input();
  }

  addAllBankaDosyalar(data: any) {
    this.localValue.secilen_takipler.forEach(takip => {
      this.addAllBankaDosya(data, takip);
    })
  }



  async createTask() {
    this.onFly = true;
    let tasks: Array<Task> = [];
    let takip: any;
    for (takip of this.localValue.secilen_takipler) {
      let task: Task = new Task();
      task.dosya_esas_no = takip.dosyaNo;
      task.birim_adi = takip.birimAdi;
      task.birim_id = takip?.birimId;
      task.uyap_dosya_acilis_tarihi = takip.dosyaAcilisTarihi ? new Date(this.$uyapHelper.formatDate(takip.dosyaAcilisTarihi)).toString() : "";
      task.dosya_id = takip.dosyaId;
      task.task_type_id = TaskTuru.toplu_haciz_gonder;
      task.data = takip.task.response;
      tasks.push(task);
    }
    await this.$store.dispatch('postTasks', tasks);
    await this.$router.push(ProgramPaths.eicrapro + '/islemler');
    this.genelIslemForm.activeStep++;
    this.onFly = false;
  }
}

enum TopluHacizDurum {
  bosta = 1,
  hazirlaniyor = 2,
  hazir = 3
}
